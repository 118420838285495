
const Currency = [
    {
        value: "USD",
        name: "United States Dollars"
    },
    {
        value: "EUR",
        name: "Euro"
    },
    {
        value: "GBP",
        name: "United Kingdom Pounds"
    },
    {
        value: "DZD",
        name: "Algeria Dinars"
    },
    {
        value: "ARP",
        name: "Argentina Pesos"
    },
    {
        value: "AUD",
        name: "Australia Dollars"
    },
    {
        value: "ATS",
        name: "Austria Schillings"
    },
    {
        value: "BSD",
        name: "Bahamas Dollars"
    },
    {
        value: "BBD",
        name: "Barbados Dollars"
    },
    {
        value: "BEF",
        name: "Belgium Francs"
    },
    {
        value: "BMD",
        name: "Bermuda Dollars"
    },
    {
        value: "BRR",
        name: "Brazil Real"
    },
    {
        value: "BGL",
        name: "Bulgaria Lev"
    },
    {
        value: "CAD",
        name: "Canada Dollars"
    },
    {
        value: "CLP",
        name: "Chile Pesos"
    },
    {
        value: "CNY",
        name: "China Yuan Renmimbi"
    },
    {
        value: "CYP",
        name: "Cyprus Pounds"
    },
    {
        value: "CSK",
        name: "Czech Republic Koruna"
    },
    {
        value: "DKK",
        name: "Denmark Kroner"
    },
    {
        value: "NLG",
        name: "Dutch Guilders"
    },
    {
        value: "XCD",
        name: "Eastern Caribbean Dollars"
    },
    {
        value: "EGP",
        name: "Egypt Pounds"
    },
    {
        value: "FJD",
        name: "Fiji Dollars"
    },
    {
        value: "FIM",
        name: "Finland Markka"
    },
    {
        value: "FRF",
        name: "France Francs"
    },
    {
        value: "DEM",
        name: "Germany Deutsche Marks"
    },
    {
        value: "XAU",
        name: "Gold Ounces"
    },
    {
        value: "GRD",
        name: "Greece Drachmas"
    },
    {
        value: "HKD",
        name: "Hong Kong Dollars"
    },
    {
        value: "HUF",
        name: "Hungary Forint"
    },
    {
        value: "ISK",
        name: "Iceland Krona"
    },
    {
        value: "INR",
        name: "India Rupees"
    },
    {
        value: "IDR",
        name: "Indonesia Rupiah"
    },
    {
        value: "IEP",
        name: "Ireland Punt"
    },
    {
        value: "ILS",
        name: "Israel New Shekels"
    },
    {
        value: "ITL",
        name: "Italy Lira"
    },
    {
        value: "JMD",
        name: "Jamaica Dollars"
    },
    {
        value: "JPY",
        name: "Japan Yen"
    },
    {
        value: "JOD",
        name: "Jordan Dinar"
    },
    {
        value: "KRW",
        name: "Korea (South) Won"
    },
    {
        value: "LBP",
        name: "Lebanon Pounds"
    },
    {
        value: "LUF",
        name: "Luxembourg Francs"
    },
    {
        value: "MYR",
        name: "Malaysia Ringgit"
    },
    {
        value: "MXP",
        name: "Mexico Pesos"
    },
    {
        value: "NLG",
        name: "Netherlands Guilders"
    },
    {
        value: "NZD",
        name: "New Zealand Dollars"
    },
    {
        value: "NOK",
        name: "Norway Kroner"
    },
    {
        value: "PKR",
        name: "Pakistan Rupees"
    },
    {
        value: "XPD",
        name: "Palladium Ounces"
    },
    {
        value: "PHP",
        name: "Philippines Pesos"
    },
    {
        value: "XPT",
        name: "Platinum Ounces"
    },
    {
        value: "PLZ",
        name: "Poland Zloty"
    },
    {
        value: "PTE",
        name: "Portugal Escudo"
    },
    {
        value: "ROL",
        name: "Romania Leu"
    },
    {
        value: "RUR",
        name: "Russia Rubles"
    },
    {
        value: "SAR",
        name: "Saudi Arabia Riyal"
    },
    {
        value: "XAG",
        name: "Silver Ounces"
    },
    {
        value: "SGD",
        name: "Singapore Dollars"
    },
    {
        value: "SKK",
        name: "Slovakia Koruna"
    },
    {
        value: "ZAR",
        name: "South Africa Rand"
    },
    {
        value: "KRW",
        name: "South Korea Won"
    },
    {
        value: "ESP",
        name: "Spain Pesetas"
    },
    {
        value: "XDR",
        name: "Special Drawing Right (IMF)"
    },
    {
        value: "SDD",
        name: "Sudan Dinar"
    },
    {
        value: "SEK",
        name: "Sweden Krona"
    },
    {
        value: "CHF",
        name: "Switzerland Francs"
    },
    {
        value: "TWD",
        name: "Taiwan Dollars"
    },
    {
        value: "THB",
        name: "Thailand Baht"
    },
    {
        value: "TTD",
        name: "Trinidad and Tobago Dollars"
    },
    {
        value: "TRL",
        name: "Turkey Lira"
    },
    {
        value: "VEB",
        name: "Venezuela Bolivar"
    },
    {
        value: "ZMK",
        name: "Zambia Kwacha"
    },
    {
        value: "EUR",
        name: "Euro"
    },
    {
        value: "XCD",
        name: "Eastern Caribbean Dollars"
    },
    {
        value: "XDR",
        name: "Special Drawing Right (IMF)"
    },
    {
        value: "XAG",
        name: "Silver Ounces"
    },
    {
        value: "XAU",
        name: "Gold Ounces"
    },
    {
        value: "XPD",
        name: "Palladium Ounces"
    },
    {
        value: "XPT",
        name: "Platinum Ounces"
    },
]

export default Currency;