import React, { useState, useContext }  from "react";
import "./UploadDocumentComponents/upload.css"
import ManuscriptComponent from "./HomePageComponents/ManuscriptComponent";
import Description from "./HomePageComponents/Description";
import UploadDocument from "./UploadDocument"
import About from "./HomePageComponents/About";
import { Redirect } from "react-router";
import { UserContext } from '../App'
import { useHistory } from 'react-router-dom'


const Home=()=>{
    const history = useHistory()
    const { state, dispatch } = useContext(UserContext)
    const [isAuth,setIsAuth]=useState(true);
    const fun = () => {
        if(state){
            history.push('/uploadDocument');
        }
        else{
            history.push('/login')
        }
    }
    return(
        <div>
            <div style={{marginLeft: "7%"}}>
                <About/>
                <h3 style={{backgroundColor: "grey", width: "60%"}}>Manuscript</h3>
                <div style={{marginLeft: "5%"}}>
                    <p style={{width: "40%"}}>Our experts will improve the language of your Manuscript helping you reach the highest academic standards.</p>
                    <ManuscriptComponent/>
                    <button onClick={fun} className='btn' style={{backgroundColor: "cyan", width: "20%", cursor:"pointer"}}>Select Manuscript</button>
                </div>
                <Description/>
                <br/><br/>
            </div>
        </div>
    )
}

export default Home