import React from "react";

function Description(){
    return (
        <div className="description">
            <ul style={{fontSize: "1rem", width: "40%"}}>
                <li>Editing for language, readability and native tone</li>
                <li>Editing the structure, flow and presentation</li>
                <li>Editor's report to highlight the focus area of your
                Manuscript</li>
                <li>Layout formatting to make sure your Manuscript is presentable</li>
                <li>Multiple round of re-editing for up to 120 days to help
                collaborate with our editors and submit the
                best version of your Manuscript</li>
                <li>Plagiarism check to help you eliminate instances of similarity with published work</li>
            </ul>
        </div>
    )
}

export default Description