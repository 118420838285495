import React from "react";

function Footer(){
    return (
        <div>
            <footer className="footer">
            </footer>
        </div>
    )
}

export default Footer;