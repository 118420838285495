import React, { useContext, useRef, useEffect, useState } from 'react'
import { UserContext } from '../App'
import { Link, useHistory } from 'react-router-dom'
import './UploadDocumentComponents/upload.css'
import {GoogleLogout} from 'react-google-login';
import logo from '../logo1.svg'

function Header() {
  const history = useHistory()
  const { state, dispatch } = useContext(UserContext)
  const auth = state;
  // console.log(auth);
  const clientId =
    "929313856124-kaegmn8b5kj1glkc5nq470na4jtt3prd.apps.googleusercontent.com";

  const onSignoutSuccess = () => {
    localStorage.clear()
    dispatch({ type: "CLEAR" })
    history.push('/login');
    // console.log("fnction")
  }

  const renderList = () => {
    if (state) {
      let obj = state;
      if(!state.name)
      {
        obj = JSON.parse(state);
      }
      return [
        <li><Link className="link" to="/">Home</Link></li>,
        <li>{obj.name}</li>,

        
        <li>
          {
            
            obj.googleId?<GoogleLogout
            clientId={clientId}
            className="googleLogout"
            // buttonText="Sign Out"
            onLogoutSuccess={onSignoutSuccess}
          >
            <span >Sign Out</span>
          </GoogleLogout>:<li>
          <button className="logout waves-effect waves-light #c62828 red darken-3 btn-small"
            style={{width: "4rem",marginLeft: "-10%"}} 
            onClick={() => {
              localStorage.clear()
              dispatch({ type: "CLEAR" })
              history.push('/login')
            }}
          >
            Logout
          </button>
        </li>
          }
          
        </li>
      ]
    } else {
      return [
        <li><Link className="link" to="/">Home</Link></li>,
        <li><Link className="link" to="/login">Sign in</Link></li>,
        <li><Link className="link" to="/register">Sign up</Link></li>
      ]
    }
  }
  return (
    <nav>
      <div className="header">
        <img style={{fontWeight: "bold" , marginLeft: "-2%"}} width="10%" height="100%" src={logo} alt="Logo" />
        <Link to={state ? "/" : "/login"} ></Link>
        <ul id="nav-mobile" className="homeLogin">
          {renderList()}
        </ul>
      </div>

    </nav>

  )
}

export default Header










// import React from "react";
// import { Link } from "react-router-dom";

// function Header(){
//     return (
//         <div className="header">
//             <h3 className="logo">Logo</h3>
//             <div className="homeLogin">
//                 <Link className="home" to="/">Home</Link>
//                 <Link className="login" to="/login">Login</Link>
//             </div>
            
//         </div>
//     )
// }

// export default Header;