
const Languages = [
    "English",
    "Hindi",
    "Telugu",
    "Spanish",
    "German",
    "French",
    "Mandarin",
    "Dutch",
    "Arabic",
    "Urdu"
]

export default Languages;