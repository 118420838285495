import React, { useEffect, createContext, useReducer, useContext } from 'react';
import { reducer, initialState } from './reducer'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";
import Home from './pages/Home';
import "./pages/UploadDocumentComponents/upload.css"
import Login from './pages/Login';
import Header from "./pages/Header";
import Footer from './pages/Footer';
import UploadDocument from './pages/UploadDocument';
import Register from './pages/Register';


export const UserContext = createContext()


// const Routing = ()=>{
//   const history = useHistory()
//   const {state,dispatch} = useContext(UserContext)
//   useEffect(()=>{
//     const user = JSON.parse(localStorage.getItem("user"))
//     if(user){
//       dispatch({type:"USER",payload:user})
//     }else{
//       history.push('/')
//     }
//   },[])
//   return(
//     <Switch>
//       <Route exact path="/" >
//       <Home />
//       </Route>
//       <Route path="/login">
//         <Login />
//       </Route>
//       <Route path="/register">
//         <Register />
//       </Route>
//       <Route path="/profile">
//         <Profile />
//       </Route>
//       <Route path="/uploadDocument">
//         <UploadDocument />
//       </Route>
//       <Route path="/edit">
//         <EditUser />
//       </Route>
//     </Switch>
//   )
// }


function App() {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <UserContext.Provider value={{ state, dispatch }}>
      <Router>
        <Header/>
        {/* <Routing/> */}
        <Route exact path="/" >
            <Home />
        </Route>
        <Route path="/login">
            <Login />
        </Route>
        <Route path="/register">
            <Register />
        </Route>
        <Route path="/uploadDocument">
            <UploadDocument />
        </Route>
            <Footer/>
        </Router>
    </UserContext.Provider>
  );
}

export default App;
