import React, { useState } from "react";
import Languages from "./Languages";
import AreaOfStudy from "./AreaOfStudy";
import Currency from "./Currency";
import PayPal from "../paymentPage/PayPal";
import { SERVER_URL, uploadDocument } from '../../config/config.json'
function Body(){
    const [options] = useState(Languages);
    const [study] = useState(AreaOfStudy);
    const [currency] = useState(Currency);
    const [checkout, setCheckOut] = useState(false);
    const [check,setCheck]=useState(true);
    const [word, setWord] = useState(0);
    const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
    // {var z=document.getElementById("fileInformation")};
    
    function wordCount() {
        var fileInput = 
            document.getElementById('file');

        setCheck(false);  
        var filePath = fileInput.value;
        var allowedExtensions = /(\.docx)$/i;
          
        if (!allowedExtensions.exec(filePath)) {
            // alert('Invalid file type');
            var filetype=document.getElementById('valid');
            filetype.innerHTML="Invalid File";
            setCheck(true);
            fileInput.value = '';
            return false;
        } 
    }

    function calculateWords(event) {
        if (window.File && window.FileReader && window.FileList && window.Blob) {
            console.log("words");
            var doc = document.getElementById("file");
            var f = doc.files[0];
            if (!f) {
                alert("Failed to load file");
                //validate file types yet to come
            } else if (false) {
                alert(f.type + " is not a valid text file.");
            } else {
                var r = new FileReader();//create file reader object
                r.readAsText(f);//read file as text
                setCheck(false)
                setCheckOut(false)
                //attach function to execute when loading file finishes. 
                r.onload = function (e) {
                    var contents = e.target.result;
                    var res = contents.split(" ");
                    console.log(res.length);
                    var x = res.length;
                    setWord(x);
                    document.getElementById("fileInformation").value=x;
                    document.getElementById("amount").value=Math.max(1,x/100);
                }
            }
        } else {
            alert('The File APIs are not fully supported by your browser.');
        }
    }
    

	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
        calculateWords(event)
	};

	const handleSubmission = () => {
		const formData = new FormData();

		formData.append('File', selectedFile);

		fetch(
			`${SERVER_URL}${uploadDocument}`,
			{
				method: 'POST',
				body: formData,
			}
		)
			.then((response) => response.json())
			.then((result) => {
				console.log('Success:', result);
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	    };

    return (
        <div className="bodyClass">
            <div className="shadow">
                <h2>Upload your document</h2>
                <p style={{fontSize: "0.8rem", color: "red"}}>Word / pdf document only</p>
                <input 
                    type="file" id="file" 
                    accept=".docx,.pdf" 
                    onChange={changeHandler} 
                /><br/>
                {isFilePicked ? (
                    <div>
                        <p>Filename: {selectedFile.name}</p>
                        <p>
                            lastModifiedDate:{' '}
                            {selectedFile.lastModifiedDate.toLocaleDateString()}
                        </p>
                    </div>
                ) : (
                    <p>Select a file to show details</p>
                )}
                <div>
                    <button onClick={handleSubmission}>Upload</button>
                </div>
                
                <span id="valid" style={{color: "red", fontSize: "0.7rem"}}></span>
                <h4>About your document</h4>
                <p className="wordCount">Word Count *:</p>
                <input  className="count inputboxcolor" id="fileInformation" type="number" placeholder="0"/>
                
                <p style={{fontSize: "0.8rem", position: "relative", top: "-10px"}}>Please input your actual word count of the paper to have correct quote in next page.</p>
                <p className="chooseLanguage">Choose editing language *:</p>
                <select single className="language inputboxcolor">
                    {options.map((element, index) => <option key={index}>{element}</option>)}
                </select>
                <p className="studyArea">Area of study *:</p>
                <select  className="inputboxcolor" type="text" name="study" placeholder="select area of study">
                    {study.map((element, index) => <option key={index}>{element}</option>)}
                </select>
                <p class="fontofdocinfo">Amount in USD:</p>
                <input  className="count inputboxcolor" id="amount" type="number" placeholder="0" readOnly={true}/>

                <p style={{fontSize: "0.8rem",color: "red"}} class="fontofdocinfo">*=required</p>
                <div className="paymentBtn">
                    {checkout ? (
                        <PayPal word={word}/>
                        ) : 
                        (<button
                        onClick={() => {setCheckOut(true);}}
                        disabled={check? true:false}
                        >
                        Checkout
                        </button>
                    )}
                </div>
            </div>
            <br/><br/>
        </div>
    )
}

export default Body;
















// import React, { useState } from "react";
// import Languages from "./Languages";
// import AreaOfStudy from "./AreaOfStudy";
// import Currency from "./Currency";
// import PayPal from "../paymentPage/PayPal";
// function Body(){
//     const [options] = useState(Languages);
//     const [study] = useState(AreaOfStudy);
//     const [currency] = useState(Currency);
//     const [checkout, setCheckOut] = useState(false);
//     const [check,setCheck]=useState(true);

    
//     function fileValidation() {
//         var fileInput = 
//             document.getElementById('file');

//         setCheck(false);  
//         var filePath = fileInput.value;
//         var allowedExtensions = /(\.docx)$/i;
          
//         if (!allowedExtensions.exec(filePath)) {
//             // alert('Invalid file type');
//             var filetype=document.getElementById('valid');
//             filetype.innerHTML="Invalid File";
//             setCheck(true);
//             fileInput.value = '';
//             return false;
//         } 
//     }
//     return (
//         <div className="bodyClass">
//             <div className="shadow">
//                 <h2>Upload your document</h2>
//                 <p style={{fontSize: "0.8rem", color: "red"}}>Word document only</p>
//                 <input type="file" id="file" onChange={fileValidation} /><br/>
//                 <span id="valid" style={{color: "red", fontSize: "0.7rem"}}></span>
//                 <h4>About your document</h4>
//                 <p className="wordCount">Word Count *:</p>
//                 <input  className="count inputboxcolor" type="number" placeholder="1200-15000"/>
//                 <p style={{fontSize: "0.8rem", position: "relative", top: "-10px"}}>Please input your actual word count of the paper to have correct quote in next page.</p>
//                 <p className="chooseLanguage">Choose editing language *:</p>
//                 <select single className="language inputboxcolor">
//                     {options.map((element, index) => <option key={index}>{element}</option>)}
//                 </select>
//                 <p className="studyArea">Area of study *:</p>
//                 <select  className="inputboxcolor" type="text" name="study" placeholder="select area of study">
//                     {study.map((element, index) => <option key={index}>{element}</option>)}
//                 </select>
//                 <p class="fontofdocinfo">Select currency *:</p>
//                 <select class="inputboxcolor" name="currency">
//                     {currency.map((element, index) => <option key={index} value={element.value}>{element.name}</option>)}
//                 </select>
//                 <p style={{fontSize: "0.8rem",color: "red"}} class="fontofdocinfo">*=required</p>
//                 <div className="paymentBtn">
//                     {checkout ? (
//                         <PayPal />
//                     ) : (
//                         <button
//                         onClick={() => {
//                             setCheckOut(true);
//                         }}
//                         disabled={check? true:false}
//                         >
//                         Checkout
//                         </button>
//                     )}
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Body;