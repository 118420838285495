import React, {useEffect, useState} from "react";

const Page1_Manuscript=()=>{

    const [start,setStart] = useState(0)
    const [end,setEnd] = useState(0)
    const [count,setCount] = useState(1)
    const [check,setCheck] = useState(true)

    useEffect(() => {
        console.log(start, end);
        if (Number(start) > Number(end)) {
          setCheck(true);
        } else {
          setCheck(false);
        }
      }, [start, end]);

    const handleChange=()=>{
        let avg=(Number(start)+Number(end))/2
        avg=avg/100
        setCount(Math.max(1,avg));
        if(Number(start)>Number(end)){
            setCount(1)
        }
    }

    return (
        <div>
            <p style={{color: "red"}}>Price calculator for Manuscript</p>
            <h6 style={{marginBottom: "5px"}}>Select Word Count</h6>
            <input type="number" placeholder="starting_limit" onChange={ e => {setStart(e.target.value) }}/>
            <input type="number" placeholder="ending_limit" onChange={e=>{setEnd(e.target.value)}}/>
            <button id="checkBtn" disabled={check ? true : false} style={{backgroundColor: "dodgerblue" , width: "100px"}}onClick={handleChange}>check</button>
            <p style={{color: "red"}}>Starts from :</p>
            <h2 style={{color: "red", marginLeft: "10%"}}>$ {count}</h2>
        </div>
    )
}

export default Page1_Manuscript