import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import PhoneInput from 'react-phone-number-input'
import { SERVER_URL, register_endpoint } from '../config/config.json'
import '../index.css'
import axiox from "axios";


function Register() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [mobile, setMobile] = useState("");
    const [error, setError] = useState("");
    let history = useHistory();
  
    const register = (e) => {
      e.preventDefault();
      axiox
        .post(`${SERVER_URL}${register_endpoint}`, {
          name,
          email,
          password,
          mobile,
        })
        .then((response) => {
          console.log("response", response);
          localStorage.setItem(
            "login",
            JSON.stringify({
              userLogin: true,
              token: response.data.access_token,
            })
          );
          setError("");
          setName("");
          setEmail("");
          setPassword("");
          setMobile("");
          history.push("/login");
        })
        .catch((error) => {setError(error.response.data.message)
                console.log(error.response.data.message);
        });
    };

    return (
      <div className="block" >
            
            <div className="form ">
            <h2 style={{textAlign: 'left',textDecoration: "underline",color: "purple"}}>New Here just Register...</h2>
                <form onSubmit={register}>
                <span>{error}</span><br/>
                <div style={{display : "flex"}}>
                <input 
                    
                    type='text'
                    placeholder='Name'
                    value={name}
                    onChange={(e)=>setName(e.target.value)}
                    required
                />
                <span>*required</span>
                {/* <input
                    
                    type='text'
                    placeholder='Last Name'
                    value={lastName}
                    onChange={(e)=>setLastName(e.target.value)}
                /> */}
                </div>
                
                <input
                    
                    type='email'
                    placeholder='Email'
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                    required
                />
                <span>*required</span>
                <input
                    
                    type='password'
                    placeholder='Password'
                    value={password}
                    onChange={(e)=>setPassword(e.target.value)}
                    required
                />
                <span>*required</span>
                <input
                    
                    type='number'
                    placeholder='Mobile Number'
                    value={mobile}
                    onChange={(e)=>setMobile(e.target.value)}
                />
                <br/><br/>
                <button className="btn" type="submit" style={{backgroundColor: "dodgerblue", width: "30%", marginTop: "-20px"}}
                >
                    Sign up
                </button>
                </form>
                <h5>
                    <Link to="/login">Already have an account?</Link>
                </h5>
            </div>
        </div>
    )
}

export default Register


  // const history=useHistory()
    // const [name,setName] = useState("")
    // // const [lastName,setLastName] = useState("")
    // const [mobile, setMobile] = useState("");
    // const [password,setPassword] = useState("")
    // const [email, setEmail] = useState("")
    
    // const PostData = () => {
    //     if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
    //         //M.toast({ html: "invalid email", classes: "#e57373 red" })
    //         return;
    //     }
    //     // if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(password)) {
    //     //      //M.toast({ html: "password should contain atleast one number and atleast one special charecter", classes: "#e57373 red" })
    //     //      return;
    //     //  }
    //     fetch("http://localhost:3005/api/auth/register", {
    //         method: "post",
    //         headers: {
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify({
    //            name,
    //         //    lastName,
    //            email,
    //            password,
    //            mobile,
    //         })
    //     }).then(res => res.json())
    //             .then(data => {
    //                 // if (data.error) { 
    //                 //     //M.toast({html: data.error, classes:"#e57373 red"})
    //                 // }
    //                 // else {
    //                     //M.toast({ html: data.message, classes: "#43a047 green darken-1" })
    //                     history.push('/login')
    //                     //console.log(data);
    //                 //}
    //             }).catch(err => {
    //                 console.log(err)
    //             })
    //     }

//onClick={()=>PostData()}




// import React, { Component } from "react";
// import { useState } from "react";
// import { BrowserRouter, Route, Switch , useHistory} from 'react-router-dom';
// import axiox from "axios";
// import "../index.css"

// const Register = (props) => {
//     const [name, setName] = useState("");
//     const [email, setEmail] = useState("");
//     const [password, setPassword] = useState("");
//     const [mobile, setMobile] = useState("");
//     const [error, setError] = useState("");
//     let history = useHistory();
  
//     const register = (e) => {
//       e.preventDefault();
//       axiox
//         .post("http://localhost:3005/api/auth/register", {
//           name,
//           email,
//           password,
//           mobile,
//         })
//         .then((response) => {
//           console.log("response", response);
//           localStorage.setItem(
//             "login",
//             JSON.stringify({
//               userLogin: true,
//               token: response.data.access_token,
//             })
//           );
//           setError("");
//           setName("");
//           setEmail("");
//           setPassword("");
//           setMobile("");
//           props.setIsLoggedin(true);
//           history.push("/");
//         })
//         .catch((error) => {setError(error.response.data.message)
//                 console.log(error.response.data.message);
//         });
//     };
//     return (
//         <div className="block" style={{marginLeft: "50%", marginTop: "-23.5%"}}>
//             <h2 style={{textAlign: 'left',marginLeft: "-20%",textDecoration: "underline",color: "purple"}}>New Here just Register...</h2>
//             <form className="form" onSubmit={register}>
//                 <span>{error}</span><br/>
//                 <input type="text" name="name"  placeholder="name" value={name} onChange={(e) => setName(e.target.value)} required/><span>*required</span>
//                 <input type="email" name="email"  placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required/><span>*required</span>
//                 <input type="password" name="password"  placeholder="password" value={password} onChange={(e) => setPassword(e.target.value)} required/><span>*required</span>
//                 <input type="number" name="mobile"  placeholder="Mobile Number" value={mobile} onChange={(e) => setMobile(e.target.value)}/>
//                 <br/>
//                 <button className="btn" type="submit" style={{backgroundColor: "dodgerblue", width: "30%", marginTop: "-20px"}}>Register</button>
//             </form>
//         </div>
//     );
//   };
  
// export default Register;
