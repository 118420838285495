import React from "react"

function About(){
    return(
        <div style={{width: "40%"}}>
            <h2>Manuscript</h2>
            <p>We will handle the language editing and make sure that your paper is free of grammatical, spelling and common errors.</p>
        </div>
    )
}

export default About