
const AreaOfStudy = [
    "Physics",
    "Mathematics",
    "Designing",
    "Aeronautics and Aviation Science",
    "Anthropology",
    "Art",
    "Business Administration",
    "Chemistry",
    "Economics",
    "Education",
    "Engineering",
    "Environmental Science and Health",
    "Journalism and Mass Communication",
    "Music",
    "Nursing",
    "Oceanography",
    "Pharmacy",
    "Photography",
    "Physical Therapy",
    "Political Science and International Relations",
    "Psychology",
    "Public Relations and Administration",
    "Statistics",
    "Urban Planning"
]

export default AreaOfStudy;