import React, { useState, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { UserContext } from '../App'
import './UploadDocumentComponents/upload.css'
import './login.css'
import GoogleLogin from 'react-google-login'
import axiox from "axios";
import { 
    GOOGLE_CLIENT_ID, 
    SERVER_URL, 
    REDIRECT_URI,
    login_endpoint,
    register_endpoint 
} from '../config/config.json'


function Login(props) {
    const history = useHistory()
    const { state, dispatch } = useContext(UserContext)
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const [error, setError] = useState("")

    const clientId = GOOGLE_CLIENT_ID;
    const Server_url = SERVER_URL;

    const onLoginSuccess = (res) => {
        localStorage.setItem("jwt", res.getAuthResponse().id_token)
        localStorage.setItem("user", JSON.stringify(res.profileObj))
        dispatch({ type: "USER", payload: res.profileObj })
        history.push('/uploadDocument')
        // console.log(res.profileObj);
    }

    const onLoginFailure = () => {
        console.log('Failure');
    }
    const login = (e) => {
                e.preventDefault();
                axiox
                .post(`${Server_url}${login_endpoint}`, {
                    email,
                    password,
                })
                .then((response) => {
                    // console.log("response", response);
                    // console.log("response", response.config.data);
                    // console.log("response", response.config.data[4]);
                    localStorage.setItem(
                    "user",
                    JSON.stringify(
                        response.data
                    )
                    );
                    setError("");
                    setEmail("");
                    setPassword("");
                    dispatch({ type: "USER", payload: response.data })
                    history.push("/uploadDocument");
                })
                .catch((error) => setError(error.response.data.message));
            };
    return (
            <div className="">
                <div className="block">
                    <h2 style={{textAlign: 'left',marginLeft: "-13%",textDecoration: "underline",color: "purple"}}>Already a user just Login...</h2>
                    <form className="form" onSubmit={login}>
                        <span>{error}</span><br/>
                        <input type="email" name="email"  placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                        <input type="password" name="password"  placeholder="password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                        <br/><br/>
                        <button className="btn"  style={{backgroundColor: "dodgerblue", width: "25%"}} type="submit">Login</button>
                    </form>
                    <h5>
                        <Link to="/register"  style={{textAlign: 'left',marginLeft: "-13%"}}>Don't have an account?</Link>
                    </h5>
                    <GoogleLogin
                        style={{textAlign: 'left',marginLeft: "-13%"}}
                        className="g-Login"
                        redirectUri = {REDIRECT_URI}
                        clientId={clientId}
                        // scope="openid"
                        // buttonText="Sign In with Google"
                        onSuccess={onLoginSuccess}
                        onFailure={onLoginFailure}
                        cookiePolicy={"single_host_origin"}
                        isSignedIn={true}
                        // redirectPath='/'
                        >
                            {/* <span>| Sign In with Google</span> */}
                    </GoogleLogin>
                </div>
            </div>
        )
}

export default Login

            

// const PostData = () => {
    //     if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
    //         //M.toast({ html: "invalid email", classes: "#e57373 red" })
    //         return;
    //     }
        // if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(password)) {
        //      //M.toast({ html: "password should contain atleast one number and atleast one special charecter", classes: "#e57373 red" })
        //      return;
        //  }






        // return (
        //     <div className="block">
        //             <h2 style={{textAlign: 'left',marginLeft: "-20%",textDecoration: "underline",color: "purple"}}>Already a user just Login...</h2>
        //         <form className="form">
        //             <h2>Login</h2>
        //             <input
    
        //                 type='text'
        //                 placeholder='Email'
        //                 value={email}
        //                 onChange={(e) => setEmail(e.target.value)}
        //             />
        //             <input
    
        //                 type='password'
        //                 placeholder='Password'
        //                 value={password}
        //                 onChange={(e) => setPassword(e.target.value)}
        //             />
        //             <button className="btn"  style={{backgroundColor: "dodgerblue", width: "25%"}}
        //                 onClick={() => PostData()}>
        //                 Sign in
        //             </button>
        //             <h5>
        //                 <Link to="/register">Don't have an account?</Link>
        //             </h5>
    
        //             <GoogleLogin
        //                 className="g-Login"
        //                 redirectUri="http://localhost:3000/home"
        //                 clientId={clientId}
        //                 // scope="openid"
        //                 // buttonText="Sign In with Google"
        //                 onSuccess={onLoginSuccess}
        //                 onFailure={onLoginFailure}
        //                 cookiePolicy={"single_host_origin"}
        //                 isSignedIn={true}
        //             // redirectPath='/'
        //             >
        //                 {/* <span>| Sign In with Google</span> */}
        //             </GoogleLogin>
        //         </form>
        //     </div>
        // )
        // fetch("http://localhost:3005/api/auth/login", {
        //     method: "post",
        //     headers: {
        //         "Content-Type": "application/json"
        //     },
        //     body: JSON.stringify({
        //         email,
        //         password,
        //     })
        // }).then(res => res.json())
        //     .then(data => {
        //         if (data.error) {
        //             //M.toast({html: data.error, classes:"#e57373 red"})
        //         }
        //         else {
        //             //M.toast({ html: data.message, classes: "#43a047 green darken-1" })

        //             localStorage.setItem("jwt", data.token)
        //             localStorage.setItem("user", JSON.stringify(data.user))
        //             dispatch({ type: "USER", payload: data.user })
        //             history.push('/')
        //             console.log(data);
        //         }
        //     }).catch(err => {
        //         console.log(err)
        //     })


// import React, { Component } from "react";
// import { useState } from "react";
// import Block from "./Block.json"
// import LoginWithGoogle from "./LoginWithGoogle";
// import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
// import { BrowserRouter, Route, Switch , useHistory} from 'react-router-dom';
// import axiox from "axios";
// import "../../index.css"
// import LinkedInPage from "./LoginWithLinkedin";
// import Register from "../Register";

// const Body = (props) => { 
//     const [email, setEmail] = useState("");
//     const [password, setPassword] = useState("");
//     const [error, setError] = useState("");
//     let history = useHistory();

//     const login = (e) => {
//         e.preventDefault();
//         axiox
//         .post("http://localhost:3005/api/auth/login", {
//             email,
//             password,
//         })
//         .then((response) => {
//             console.log("response", response);
//             localStorage.setItem(
//             "login",
            
//             JSON.stringify({
//                 userLogin: true,
//                 token: response.data.access_token,
//             })
//             );
//             setError("");
//             setEmail("");
//             setPassword("");
//             props.setIsLoggedin(true);
//             history.push("/");
//         })
//         .catch((error) => setError(error.response.data.message));
//     };
//     return (
//         <div className="bodyClass">
//             <h1 style={{textAlign: "center",fontWeight: "normal",marginBottom: "-5%", textDecoration: "underline"}}>Register or Login</h1>
//             <div className="block">
//                 <h2 style={{textAlign: 'left',marginLeft: "-20%",textDecoration: "underline",color: "purple"}}>Already a user just Login...</h2>
//                 <form className="form" onSubmit={login}>
//                     <span>{error}</span><br/>
//                     <input type="email" name="email"  placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
//                     <input type="password" name="password"  placeholder="password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
//                     <br/><br/>
//                     <button className="btn"  style={{backgroundColor: "dodgerblue", width: "25%"}} type="submit">Login</button>
//                 </form>
//                 <div className="third-party">
//                     {/* <BrowserRouter>
//                         <Switch >
//                             <Route exact path="/linkedin" component={LinkedInPopUp} />
//                             <Route path="/" component={LinkedInPage} />
//                         </Switch>
//                     </BrowserRouter> */}
//                     <LoginWithGoogle setIsLoggedin={props.setIsLoggedin}/>
//                 </div>
//             </div>
//             <Register setIsLoggedin={props.setIsLoggedin}/>
//         </div>
//     )
// }

// export default Body;






// import React from "react";
// import { useState } from "react";
// import Body from "./LoginComponents/Body";
// import "./LoginComponents/login.css"
// import Home from "./Home";
// import { Redirect } from "react-router";

// const Login=()=>{

//     const [isLoggedin,setIsLoggedin]=useState(false);
//     if(!isLoggedin){
//         // alert(isLoggedin,"1")
//         return <Body setIsLoggedin={setIsLoggedin}/>
//     }
//     // alert(isLoggedin,"1")
//     return <Redirect to="/"/>
// }

// export default Login