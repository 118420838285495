import React from "react";
import Body from "./UploadDocumentComponents/body";
import "./UploadDocumentComponents/upload.css"

function UploadDocument(){
    return (
        <div>
            <Body/>
        </div>
    )
}

export default UploadDocument;